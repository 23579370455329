import { Attachment } from "@/api/tickets";
import { InjectionKey, provide, inject, ref, Ref } from "vue";

export type AttachmentProvide = {
    attachments: Ref<Attachment[]>;
    shownAttachment: Ref<Attachment["id"] | null>;
};

export const AttachmentProvideKey: InjectionKey<AttachmentProvide> =
    Symbol.for("attachment-provide");

export function provideAttachments() {
    const attachments = ref<Attachment[]>([]);
    const shownAttachment = ref<Attachment["id"] | null>(null);

    provide(AttachmentProvideKey, { attachments, shownAttachment });

    return { attachments, shownAttachment };
}

export function injectAttachments() {
    const attachmentProvide = inject(AttachmentProvideKey, undefined);
    if (!attachmentProvide) {
        return {
            attachments: ref<Attachment[]>([]),
            shownAttachment: ref<Attachment["id"] | null>(null),
        };
    }

    return attachmentProvide;
}
