import type { Attachment } from "@/api/tickets";
import type { default as MarkdownIt } from "markdown-it";
import type Token from "markdown-it/lib/token";

export function attachmentLinks(md: MarkdownIt, attachments: Attachment[]) {
    const attachmentMap = new Map(attachments.map((a) => [a.fileName, a]));

    md.renderer.rules["link_open"] = function (tokens, idx, options, env, self): string {
        const token = tokens[idx] as Token;
        const href = token.attrGet("href");

        if (!href) {
            return self.renderToken(tokens, idx, options);
        }

        const attachment = attachmentMap.get(decodeURIComponent(href).replaceAll("+", " "));
        if (!attachment) {
            return self.renderToken(tokens, idx, options);
        }

        token.attrJoin("class", "attachment-link");
        token.attrSet("data-attachment-id", attachment.id);
        token.attrSet("href", attachment.url);
        token.attrSet("target", "_blank");

        return self.renderToken(tokens, idx, options);
    };
}

export default attachmentLinks;
