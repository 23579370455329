import axios, { AxiosError } from "axios";
import { TicketId, TicketLinkTypeId } from "./tickets";

export enum FollowUpTypes {
    Unknown = 0,

    GENERAL = 100,
    CreateTicket = 101,

    TICKETS = 200,

    LINKS = 300,
    AutoDeleteLink = 301,
}

export enum EndConditions {
    Unknown = 0,

    DATE_TIME = 100,
    DateAtUTCMidnight = 101,
    DateAtUTCTime = 102,
}

export type FollowUpId = string & { __brand: "FollowUpId" };
export type FollowUp = {
    id: FollowUpId;
    followUpType: FollowUpTypes;
    endCondition: EndConditions;
    endDate: Date | string | null;
    processed: boolean;
    ticketId: TicketId;
    linkedTicketId: TicketId | null;
    linkTypeId: TicketLinkTypeId | null;
};

export type FollowUpGetModel = {
    ticketId?: TicketId;
    linkedTicketId?: TicketId;
    linkTypeId?: TicketLinkTypeId;
};

export type FollowUpCreateModel = {
    ticketId: TicketId;
    linkedTicketId?: TicketId;
    linkTypeId?: TicketLinkTypeId;
    followUpType: FollowUpTypes;
    endCondition: EndConditions;
    endDate?: Date;
};

export type FollowUpPatchModel = {
    id: FollowUpId;

    ticketId?: TicketId;
    linkedTicketId?: TicketId;
    linkTypeId?: TicketLinkTypeId;
    followUpType?: FollowUpTypes;
    endCondition?: EndConditions;
    endDate?: Date;
};

export type FollowUpApi = {
    getFollowUps(model?: FollowUpGetModel | null): Promise<FollowUp[]>;
    createFollowUp(model: FollowUpCreateModel): Promise<FollowUp | null>;
    removeFollowUp(id: string): Promise<boolean>;
    editFollowUp(model: FollowUpPatchModel): Promise<boolean>;
};

export const followUpApi: FollowUpApi = {
    async getFollowUps(model = null) {
        let followups: FollowUp[] = [];
        if (model == null) ({ data: followups } = await axios.get<FollowUp[]>("/api/v0/followups"));
        else
            ({ data: followups } = await axios.post<FollowUp[]>("/api/v0/followups", {
                ticketId: model.ticketId,
                linkedTicketId: model.linkedTicketId ?? null,
                linkTypeId: model.linkTypeId ?? null,
            }));
        return followups;
    },
    async createFollowUp(model) {
        try {
            const { data: followUp } = await axios.put<FollowUp>("/api/v0/followups", model);
            return followUp;
        } catch (e) {
            if (e instanceof AxiosError) return null;
            else throw e;
        }
    },
    async removeFollowUp(id) {
        try {
            await axios.delete(`/api/v0/followups/${id}`);
            return true;
        } catch (e) {
            if (e instanceof AxiosError) return false;
            else throw e;
        }
    },
    async editFollowUp(model) {
        try {
            await axios.patch("/api/v0/followups", model);
            return true;
        } catch (e) {
            if (e instanceof AxiosError) return false;
            else throw e;
        }
    },
};
