import axios from "axios";
import type { ProjectConfigurationId } from "./projectConfigs";
import type { Project } from "./projects";
import type { Team, TeamId } from "./teams";
import type { User, UserEditModel, UserId } from "./users";

export type OrganizationId = string & { __brand: "OrganizationId" };

export type Organization = {
    id: OrganizationId;
    name: string;
};

export enum OrganizationPermission {
    /** Administrator access to a organization. */
    OrganizationAdministrator,
    /** Browse a organization. */
    CanViewOrganization,
    /** Can create projects for a organization. */
    CanCreateProjects,
    /** Can modify, add or remove users from a organization. */
    CanManageUsers,
}

export type OrganizationUser = {
    id: UserId;
    teams: TeamId[];
    isExclusive: boolean;
};

export type OrganizationApi = {
    getOrganizations(): Promise<Organization[]>;
    get(id: OrganizationId): Promise<Organization>;
    create(name: string): Promise<Organization>;

    createProject(
        id: OrganizationId,
        name: string,
        key: string,
        configuration: ProjectConfigurationId
    ): Promise<Project>;

    getTeams(organization: OrganizationId): Promise<TeamId[]>;
    createTeam(organization: OrganizationId, name: string): Promise<Team>;

    getUsers(organization: OrganizationId): Promise<OrganizationUser[]>;
    addUser(
        organization: OrganizationId,
        email: string | null,
        userName: string | null
    ): Promise<OrganizationUser>;
    removeUser(organization: OrganizationId, user: UserId): Promise<void>;
    editOrganizationUser(
        organization: OrganizationId,
        user: UserId,
        model: UserEditModel
    ): Promise<User>;
};

export const organizationApi: OrganizationApi = {
    async getOrganizations() {
        const { data: organizations } = await axios.get<Organization[]>("/api/v0/organizations");

        return organizations;
    },
    async get(id) {
        const { data: organization } = await axios.get<Organization>(`/api/v0/organizations/${id}`);

        return organization;
    },
    async create(name) {
        const { data: organization } = await axios.post<Organization>(`/api/v0/organizations`, {
            name,
        });
        return organization;
    },

    async createProject(id, name, key, configuration) {
        const { data: project } = await axios.post<Project>(
            `/api/v0/organizations/${id}/projects`,
            {
                name,
                key,
                configuration,
            }
        );
        return project;
    },

    async getTeams(organization) {
        const { data: teams } = await axios.get<TeamId[]>(
            `/api/v0/organizations/${organization}/teams`
        );

        return teams;
    },
    async createTeam(organization, name) {
        const { data: team } = await axios.post<Team>(
            `/api/v0/organizations/${organization}/teams`,
            {
                name,
            }
        );

        return team;
    },

    async getUsers(organization) {
        const { data: users } = await axios.get<OrganizationUser[]>(
            `/api/v0/organizations/${organization}/users`
        );

        return users;
    },
    async addUser(organization, email, userName) {
        const { data: user } = await axios.post<OrganizationUser>(
            `/api/v0/organizations/${organization}/users`,
            {
                email: email,
                userName: userName,
            }
        );
        return user;
    },
    async removeUser(organization, user) {
        await axios.delete(`/api/v0/organizations/${organization}/users/${user}`);
    },
    async editOrganizationUser(organization, user, model) {
        const { data: userResp } = await axios.patch<User>(
            `/api/v0/organizations/${organization}/users/${user}`,
            model
        );
        return userResp;
    },
};
