import { useApi } from "@/api";
import {
    TicketList,
    TicketListId,
    TicketListStub,
    TicketListUpdateModel,
    TicketListUserPermission,
} from "@/api/tickets";
import { defineStore } from "pinia";

export type TicketListsState = {
    listStubs: TicketListStub[];
    list: TicketList | null;
    permissions: TicketListUserPermission[];
};

export const useTicketListsStore = defineStore("ticketLists", {
    state: (): TicketListsState => ({
        listStubs: [],
        list: null,
        permissions: [],
    }),
    actions: {
        async load(force: boolean = false) {
            const {
                tickets: { getLists },
            } = useApi();
            if (this.$state.listStubs.length > 0 && !force) return;
            this.$state.listStubs = await getLists();
        },
        async loadList(listId: TicketListId, force: boolean = false) {
            if (this.$state.list && this.$state.list.id == listId && !force) return;
            const {
                tickets: { getList },
            } = useApi();
            const result = await getList(listId);
            this.$state.list = result;
        },
        async updateList(model: Omit<TicketListUpdateModel, "listId">) {
            if (this.$state.list == null) return;
            const {
                tickets: { updateList },
            } = useApi();
            await updateList({ listId: this.$state.list.id, ...model });
            await this.loadList(this.$state.list.id, true);
        },
        async deleteList(listId: TicketListId) {
            if (this.$state.list && this.$state.list.id == listId) this.$state.list = null;
            const {
                tickets: { deleteList },
            } = useApi();
            await deleteList(listId);
        },
        async setListPermissions(
            listId: TicketListId,
            items: TicketListUserPermission[]
        ): Promise<boolean> {
            if (items.some((i) => i.ticketListId != listId))
                throw "Some permissions items have a different list id. Stopping for safety.";
            const {
                tickets: { setListPermissions },
            } = useApi();
            return await setListPermissions(listId, items);
        },
        async loadListPermissions(listId?: TicketListId) {
            if (listId) await this.loadList(listId);
            if (this.$state.list == null) return;
            const {
                tickets: { getListPermissions },
            } = useApi();
            this.$state.permissions = await getListPermissions(listId || this.$state.list.id);
        },
    },
    getters: {},
});
