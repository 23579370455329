import axios, { AxiosError } from "axios";
import { Project, ProjectId } from "./projects";
import { Ticket, TicketId, TicketKey } from "./tickets";
import { UserId } from "./users";
import { OrganizationId } from "./organizations";
import {
    NotificationConfiguration,
    NotificationConfigurationId,
} from "./notificationConfigurations";
import { DynamicFormSchemaItem } from "@/components";

export enum SubscriptionTypes {
    Unknown = 0,

    Ticket = 10,

    Project = 20,
}

export enum SubscriberTypes {
    Unknown = 0,
    User,
    Project,
    Organization,
}

export enum NotificationMethods {
    Unknown = 0,

    //IndividualNotificationTypes = 100,
    Onsite = 101,
    Email = 102,

    //GroupNotificationTypes = 200,
    Discord = 201,
    Slack = 202,
}

export enum NotificationTypes {
    Unknown = 0,

    Individual = 1,
    News = 2,

    Digest = 11,
}

export type SubscriptionId = string & { __brand: "SubscriptionId" };

export type PartialSubscription = {
    id: SubscriptionId;
    subscriptionType: SubscriptionTypes;
    targetTicketId: TicketId | null;
    targetTicketKey: TicketKey | null;
    targetProjectId: ProjectId | null;
};

export type Subscription = PartialSubscription & {
    userId?: UserId;
    projectId?: ProjectId;
    organizationId?: OrganizationId;
    target?: Ticket | Project;
    notificationConfigurationId: NotificationConfigurationId;
    notificationConfiguration?: NotificationConfiguration;
    lastNotification?: Date;
};

export type NotifierOption = {
    name: string;
    notificationMethod: NotificationMethods;
    configurationSchema: DynamicFormSchemaItem[];
    group?: boolean;
};

export type NotificationSettingsId = string & { __brand: "NotificationSettingsId" };
export type NotificationSettings = {
    id: NotificationSettingsId;
    userId: UserId;
    ticketDigests: boolean;
    projectDigests: boolean;
    siteNews: boolean;
    minimumDelayMinutes: number;
    userNotificationMethods: UserNotificationMethod[];
};
export type ModifyNotificationSettingsModel = Omit<
    Partial<NotificationSettings>,
    "id" | "userId" | "userNotificationMethods"
> & { userId: UserId };

export type UserNotificationMethodId = string & { __brand: "UserNotificationMethodId" };
export type UserNotificationMethod = {
    id: UserNotificationMethodId;
    notificationSettingsId: NotificationSettingsId;
    notificationMethod: NotificationMethods;
    configurationData: string;
};
export type CreateUserNotificationMethodModel = Omit<
    UserNotificationMethod,
    "id" | "notificationSettingsId"
>;
export type ModifyUserNotificationMethodModel = Partial<CreateUserNotificationMethodModel> & {
    id: UserNotificationMethodId;
};

export type UserNotificationId = string & { __brand: "UserNotificationId" };
export type UserNotification = {
    id: SubscriptionId;
    title: string | null;
    message: string | null;
    changeLogs: UserNotificationData[];
};
export type UserNotificationData = {
    userName: string;
    displayName: string;
    date: string;
    changes: UserNotificationDataItem[];
};
export type UserNotificationDataItem = {
    field: string | null;
    from: string | null;
    to: string | null;
};

export type SubscriptionApi = {
    getSubscriptions(target: Subscriber[]): Promise<PartialSubscription[]>;
    getSubscribers(
        target: TicketId | ProjectId,
        targetType: SubscriptionTypes
    ): Promise<(UserId | ProjectId)[]>;
    getSubscriptionStatus(type: SubscriptionTypes, targetId: string): Promise<boolean>;
    getNotifierPlugins(): Promise<NotifierOption[]>;
    subscribe(type: SubscriptionTypes, targetId: string, userId?: UserId): Promise<SubscriptionId>;
    unsubscribe(type: SubscriptionTypes, targetId: string): Promise<boolean>;
    getNotificationSettings(): Promise<NotificationSettings>;
    modifyNotificationSettings(model: ModifyNotificationSettingsModel): Promise<boolean>;
    addNotificationMethod(model: CreateUserNotificationMethodModel): Promise<boolean>;
    removeNotificationMethod(id: UserNotificationMethodId): Promise<boolean>;
    updateNotificationMethod(model: ModifyUserNotificationMethodModel): Promise<boolean>;
    getNotifications(): Promise<UserNotification[]>;
    clearNotification(id: SubscriptionId): Promise<boolean>;
};

export type Subscriber = {
    id: string;
    type: SubscriberTypes;
};

const base = "/api/v0/subscriptions";

export const subscriptionApi: SubscriptionApi = {
    async getSubscriptions(target) {
        const { data: subs } = await axios.post<PartialSubscription[]>(`${base}`, target);
        return subs;
    },
    async getSubscriptionStatus(type, targetId) {
        const { data: subscribed } = await axios.get<boolean>(`${base}/${type}/${targetId}`);
        return subscribed;
    },
    async getNotifierPlugins() {
        const { data: plugins } = await axios.get<NotifierOption[]>(`${base}/schemas`);
        return plugins;
    },
    async subscribe(type, targetId, userId) {
        const { data: id } = await axios.post<SubscriptionId>(
            `${base}/${type}/${targetId}${userId != undefined ? "/" + userId : ""}`
        );
        return id;
    },
    async unsubscribe(type, targetId) {
        try {
            await axios.delete(`${base}/${type}/${targetId}`);
            return true;
        } catch (ex) {
            return false;
        }
    },
    async getNotificationSettings() {
        const { data: settings } = await axios.get<NotificationSettings>(
            `/api/v0/notifications/settings`
        );
        return settings;
    },
    async modifyNotificationSettings(model) {
        const { data: result } = await axios.patch<boolean>(
            `/api/v0/notifications/settings`,
            model
        );
        return result;
    },
    async addNotificationMethod(model) {
        const { data: result } = await axios.post<boolean>(`/api/v0/notifications/methods`, model);
        return result;
    },
    async removeNotificationMethod(id) {
        const { data: result } = await axios.delete<boolean>(`/api/v0/notifications/methods/${id}`);
        return result;
    },
    async updateNotificationMethod(model) {
        const { data: result } = await axios.patch<boolean>(`/api/v0/notifications/methods`, model);
        return result;
    },
    async getNotifications() {
        try {
            const { data: result } = await axios.get<UserNotification[]>(`/api/v0/notifications`);
            return result;
        } catch (e) {
            if (e instanceof AxiosError) return [];
            else throw e;
        }
    },
    async clearNotification(id) {
        const { data: result } = await axios.delete<boolean>(`/api/v0/notifications/${id}`);
        return result;
    },
    async getSubscribers(target, targetType) {
        try {
            const { data: result } = await axios.get<(UserId | ProjectId)[]>(
                `/api/v0/subscriptions/${targetType}/${target}/subscribers`
            );
            return result;
        } catch (e) {
            if (e instanceof AxiosError) return [];
            else throw e;
        }
    },
};
