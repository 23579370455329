import axios from "axios";
import { UserId } from "./users";

export type AuditLog = {
    id: string;
    entityType: string;
    date: string;
    userId: string;
    userName: string;
    action: AuditedActions;
    table: string;
    primaryKey: string;
    secondaryKey: string | null;
    changedValues: AuditLogChanges[];
    humanDateTime: string;
};

export type AuditLogsSearchResult = {
    logs: AuditLog[];
    total: number;
};

export enum AuditedActions {
    All = 0,
    Insert,
    Update,
    Delete,
}

export enum AuditLogSearchDirection {
    Ascending,
    Descending,
}

export enum AuditLogSortBy {
    EntityType,
    Date,
    UserName,
    Table,
    Action,
}

export type AuditLogChanges = {
    columnName: string;
    originalValue: string | number;
    newValue: string | number;
};

export type AuditLogSearchRequest = {
    action: AuditedActions | null;
    direction: AuditLogSearchDirection | null;
    sortBy: AuditLogSortBy | null;
    tables: string[] | null;
    timeRangeStart: string | Date | null;
    timeRangeEnd: string | Date | null;
    users: UserId[] | null;
};

export type AuditLogApi = {
    search(
        request: AuditLogSearchRequest,
        page: number | null,
        perPage: number | null
    ): Promise<AuditLogsSearchResult>;
    getTablesNames(): Promise<string[]>;
};

export const auditLogApi: AuditLogApi = {
    async search(request, page, perPage) {
        const params = new URLSearchParams();
        if (page != null) params.set("page", page.toString());
        if (perPage != null) params.set("perPage", perPage.toString());

        const { data } = await axios.post<AuditLogsSearchResult>(
            `/api/v0/audit?${params}`,
            request
        );

        return data;
    },
    async getTablesNames() {
        const { data } = await axios.get<string[]>("/api/v0/audit/tables");

        return data;
    },
};
