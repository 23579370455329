import {
    CreateUserNotificationMethodModel,
    ModifyNotificationSettingsModel,
    ModifyUserNotificationMethodModel,
    NotificationMethods,
    NotificationSettings,
    NotifierOption,
    PartialSubscription,
    Subscriber,
    SubscriberTypes,
    SubscriptionId,
    SubscriptionTypes,
    UserNotification,
    UserNotificationMethodId,
} from "@/api/subscriptions";
import { defineStore } from "pinia";
import { useApi } from "@/api";
import { isDefined } from "@/utils";
import { useAuthStore } from "./auth";
import { ProjectId, ProjectPermission } from "@/api/projects";
import { OrganizationPermission } from "@/api/organizations";
import { TicketId, TicketKey } from "@/api/tickets";
import {
    NotificationConfigurationRequest,
    NotificationConfigurationData,
} from "@/api/notificationConfigurations";
import { UserId } from "@/api/users";
import * as signalr from "@microsoft/signalr";

const { subscriptions: subscriptionApi, notificationConfigurations: notificationConfigurationApi } =
    useApi();

export type SubscriptionStatusRequest = {
    ticketId: string | null;
    projectId: string | null;
};

export type SubscriptionsState = {
    _subscriptions: PartialSubscription[];
    _notifications: UserNotification[] | null;
    _configurations: NotificationConfigurationData;
    _notificationPlugins: NotifierOption[];
    _connection: signalr.HubConnection | null;
    _notificationSettings: NotificationSettings | null;
};

export const useSubscriptionsStore = defineStore("subscriptions", {
    state: (): SubscriptionsState => ({
        _notificationPlugins: [],
        _subscriptions: [],
        _configurations: {
            global: [],
            user: [],
            project: [],
            organization: [],
        },
        _connection: null,
        _notificationSettings: null,
        _notifications: null,
    }),
    getters: {
        subscriptions: (state) => state._subscriptions,
        configurations: (state) => state._configurations,
        notifierOptions: (state) => state._notificationPlugins,
        connection: (state) => state._connection as signalr.HubConnection,
    },
    actions: {
        async load(force: boolean = false) {
            const authStore = useAuthStore();
            if (authStore.user == null || (this.$state._subscriptions.length > 0 && !force)) return;

            const user = authStore.user;
            const projects = Array.from(user.projectPermissions.entries())
                .map((e) => ({ projectId: e[0], perms: e[1] }))
                .filter(
                    (p) =>
                        p.perms.includes(ProjectPermission.CanEditTicketDescription) ||
                        p.perms.includes(ProjectPermission.ProjectAdministrator) ||
                        user.isAdmin
                )
                .map((p) => ({ id: p.projectId, type: SubscriberTypes.Project }));

            const companies = Array.from(user.organizationPermissions.entries())
                .map((e) => ({ organizationId: e[0], perms: e[1] }))
                .filter(
                    (c) =>
                        c.perms.includes(OrganizationPermission.CanCreateProjects) ||
                        c.perms.includes(OrganizationPermission.OrganizationAdministrator) ||
                        user.isAdmin
                )
                .map((c) => ({ id: c.organizationId, type: SubscriberTypes.Organization }));

            const ids: Subscriber[] = [
                ...projects,
                ...companies,
                { id: user.id, type: SubscriberTypes.User },
            ];

            await this.loadSubscriptions(ids);
            await this.loadPlugins();
            await this.createConnection();
            await this.loadNotifications();
        },
        async loadSubscriptions(targets?: Subscriber[] | undefined, replace: boolean = false) {
            let subs: PartialSubscription[] = [];
            if (isDefined(targets)) subs = await subscriptionApi.getSubscriptions(targets);
            else subs = await subscriptionApi.getSubscriptions([]);
            if (!replace) this.$state._subscriptions = subs;
            else
                subs.forEach((s) => {
                    this.$state._subscriptions.splice(
                        this.$state._subscriptions.findIndex((t) => t.id == s.id),
                        1,
                        s
                    );
                });
        },
        async loadConfigurations(targets: NotificationConfigurationRequest) {
            this.$state._configurations =
                await notificationConfigurationApi.getNotificationConfigurations(targets);
        },
        async loadPlugins() {
            this.$state._notificationPlugins = (await subscriptionApi.getNotifierPlugins()).map(
                (p) => ({
                    ...p,
                    group: p.notificationMethod > (200 as NotificationMethods),
                })
            );
        },
        async loadNotificationSettings() {
            const data = await subscriptionApi.getNotificationSettings();
            this.$state._notificationSettings = data;
        },
        async loadNotifications() {
            const data = await subscriptionApi.getNotifications();
            this.$state._notifications = data;
        },
        async createConnection() {
            this.$state._connection = new signalr.HubConnectionBuilder()
                .withUrl("/wh/notifications")
                .withAutomaticReconnect()
                .build();

            this.$state._connection.on("notification", (data: string) => {
                const model: UserNotification = JSON.parse(data) as UserNotification;
                if (this.$state._notifications?.some((n) => n.id == model.id)) {
                    const item = this.$state._notifications.find((n) => n.id == model.id);
                    if (item) item.changeLogs = model.changeLogs;
                } else {
                    this.$state._notifications?.push(model);
                }
            });

            try {
                await this._connection?.start();
            } catch (e) {
                console.log("Unable to create connection. Push notifications won't be available.");
            }
        },
        async subscribe(
            targetType: SubscriptionTypes,
            targetId: ProjectId | TicketId,
            userId?: UserId,
            targetKey?: TicketKey | null
        ) {
            const authStore = useAuthStore();

            const id = await subscriptionApi.subscribe(targetType, targetId, userId);
            if (!userId || userId == authStore.user?.id)
                this.$state._subscriptions.push({
                    id: id,
                    subscriptionType: targetType,
                    targetTicketId: (targetType == SubscriptionTypes.Ticket
                        ? targetId
                        : null) as TicketId | null,
                    targetProjectId: (targetType == SubscriptionTypes.Project
                        ? targetId
                        : null) as ProjectId | null,
                    targetTicketKey: (targetType == SubscriptionTypes.Ticket
                        ? targetKey
                        : null) as TicketKey | null,
                });
            return id;
        },
        async unsubscribe(targetType: SubscriptionTypes, targetId: ProjectId | TicketId) {
            const result = await subscriptionApi.unsubscribe(targetType, targetId);
            if (result)
                this.$state._subscriptions.splice(
                    this.$state._subscriptions.findIndex(
                        (t) =>
                            t.subscriptionType == targetType &&
                            t.targetTicketId ==
                                (targetType == SubscriptionTypes.Ticket ? targetId : null) &&
                            t.targetProjectId ==
                                (targetType == SubscriptionTypes.Project ? targetId : null)
                    ),
                    1
                );
            return result;
        },
        async editNotificationSettings(model: ModifyNotificationSettingsModel) {
            const result = await subscriptionApi.modifyNotificationSettings(model);
            if (result) await this.loadNotificationSettings();
        },
        async removeNotificationMethod(id: UserNotificationMethodId) {
            const result = await subscriptionApi.removeNotificationMethod(id);
            if (result) await this.loadNotificationSettings();
        },
        async addNotificationMethod(model: CreateUserNotificationMethodModel) {
            const result = await subscriptionApi.addNotificationMethod(model);
            if (result) await this.loadNotificationSettings();
        },
        async updateNotificationMethod(model: ModifyUserNotificationMethodModel) {
            const result = await subscriptionApi.updateNotificationMethod(model);
            if (result) await this.loadNotificationSettings();
        },
        async clearNotification(id: SubscriptionId) {
            const result = await subscriptionApi.clearNotification(id);
            this.$state._notifications?.splice(
                this.$state._notifications.findIndex((n) => n.id == id),
                1
            );
            return result;
        },
    },
});
