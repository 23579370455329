import axios from "axios";
import { Resolution, ResolutionId } from "../tickets";

export type ResolutionConfigurationId = string & { __brand: "ResolutionConfigurationId" };
export type ResolutionConfiguration = {
    id: ResolutionConfigurationId;
    name: string;
    resolutions: Resolution[];
};

export type ResolutionApi = {
    getResolutionConfigurations(): Promise<ResolutionConfiguration[]>;
    createResolutionConfiguration(name: string): Promise<ResolutionConfiguration>;
    editResolutionConfiguration(config: ResolutionConfigurationId, name: string): Promise<void>;
    deleteResolutionConfiguration(config: ResolutionConfigurationId): Promise<void>;

    createResolution(
        config: ResolutionConfigurationId,
        name: string,
        description: string | null
    ): Promise<Resolution>;
    editResolution(
        config: ResolutionConfigurationId,
        resolution: ResolutionId,
        name: string,
        description: string | null
    ): Promise<void>;
    deleteResolution(config: ResolutionConfigurationId, resolution: ResolutionId): Promise<void>;
};

export const resolutionApi: ResolutionApi = {
    async getResolutionConfigurations() {
        const { data: configs } = await axios.get<ResolutionConfiguration[]>(
            "/api/v0/project-configurations/resolutions"
        );
        return configs;
    },
    async createResolutionConfiguration(name) {
        const { data: config } = await axios.post<ResolutionConfiguration>(
            `/api/v0/project-configurations/resolutions`,
            {
                name,
            }
        );
        return config;
    },
    async editResolutionConfiguration(config, name) {
        await axios.put(`/api/v0/project-configurations/resolutions/${config}`, { name });
    },
    async deleteResolutionConfiguration(config) {
        await axios.delete(`/api/v0/project-configurations/resolutions/${config}`);
    },

    async createResolution(config, name, description) {
        const { data: resolution } = await axios.post<Resolution>(
            `/api/v0/project-configurations/resolutions/${config}/resolutions`,
            { name, description }
        );
        return resolution;
    },
    async editResolution(config, resolution, name, description) {
        await axios.put(
            `/api/v0/project-configurations/resolutions/${config}/resolutions/${resolution}`,
            {
                name,
                description,
            }
        );
    },
    async deleteResolution(config, resolution) {
        await axios.delete(
            `/api/v0/project-configurations/resolutions/${config}/resolutions/${resolution}`
        );
    },
};
