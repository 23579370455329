import axios from "axios";
import type { Operation } from "fast-json-patch";

export async function sendPatch<T>(url: string, data: Operation[]): Promise<T> {
    const { data: resp } = await axios.patch<T>(url, data, {
        headers: {
            "Content-Type": "application/json-patch+json",
        },
    });

    return resp;
}

export async function sendFile<T>(url: string, file: File, name = "file"): Promise<T> {
    const formData = new FormData();
    formData.append(name, file);
    const { data } = await axios.post<T>(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return data;
}
