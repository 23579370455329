import type { Project, ProjectId, ProjectKey } from "@/api/projects";
import type { default as MarkdownIt } from "markdown-it";
import StateInline from "markdown-it/lib/rules_inline/state_inline";

export function ticketMentions(
    md: MarkdownIt,
    projects: Map<ProjectId, Project>,
    project: Project | undefined
) {
    const projectMap = new Map(Array.from(projects.values()).map((p) => [p.key, p]));

    function createMentions(state: StateInline, silent: boolean) {
        if (state.src[state.pos] !== "#") {
            return false;
        }

        // e.g. "#PROJ-123"
        const name = state.src.slice(state.pos + 1).match(/^([A-Z]+-[1-9]\d*)(?!\w)/)?.[0];

        // e.g. "#123"
        let number = state.src.slice(state.pos + 1).match(/^([1-9]\d*)(?!\w)/)?.[0];

        // didn't get anything, or this is a #123 without a project
        if (!name && (!project || !number)) {
            return false;
        }

        let key = project?.key;

        if (name) {
            key = name.slice(0, name.indexOf("-")) as ProjectKey;
            number = name.slice(name.indexOf("-") + 1);
        }

        if (!key || !number) {
            return false;
        }

        if (!projectMap.has(key)) {
            return false;
        }

        // 1 for the #
        const len = 1 + (name?.length ?? number.length);

        if (silent) {
            state.pos += len;
            return true;
        }

        const ticket = `${key}-${number}`;

        let token = state.push("link_open", "a", 1);
        token.attrSet("href", `/tickets/${ticket}`);
        token.attrJoin("class", "ticket-mention");
        token.attrSet("data-ticket-id", ticket);

        token = state.push("text", "", 0);
        token.content = name ? ticket : `#${number}`;

        token = state.push("link_close", "a", -1);

        state.pos += len;
        return true;
    }

    const ruleName = "ticketMentions";

    try {
        md.inline.ruler.at(ruleName, createMentions);
    } catch {
        md.inline.ruler.before("emphasis", ruleName, createMentions);
    }
}
