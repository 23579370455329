import axios, { AxiosError } from "axios";
import type { OrganizationId, OrganizationPermission } from "./organizations";
import type { ProjectId, ProjectPermission } from "./projects";
import type { AvatarId, UserId } from "./users";

export type AuthUser = {
    id: UserId;
    userName: string;
    email: string;
    displayName: string | null;
    avatarId: AvatarId | null;
    isAdmin: boolean;
    organizationPermissions: Map<OrganizationId, OrganizationPermission[]>;
    projectPermissions: Map<ProjectId, ProjectPermission[]>;
};

export type AuthApi = {
    authenticated(): Promise<AuthUser | null>;
    login(userName: string, password: string, rememberMe: boolean): Promise<AuthUser | null>;
    logout(): Promise<void>;

    confirm(userId: UserId, token: string, userName: string, password: string): Promise<AuthUser>;
    change(old: string, newPass: string): Promise<void>;
    forgot(userName: string): Promise<void>;
    reset(userName: string, token: string, password: string): Promise<void>;
};

type AuthUserResponse = Omit<AuthUser, "projectPermissions" | "organizationPermissions"> & {
    organizationPermissions: Record<OrganizationId, OrganizationPermission[]>;
    projectPermissions: Record<ProjectId, ProjectPermission[]>;
};

function formatAuthUserResponse(resp: AuthUserResponse): AuthUser {
    return {
        ...resp,
        organizationPermissions: new Map(
            Object.entries(resp.organizationPermissions).map(([k, v]) => [k as OrganizationId, v])
        ),
        projectPermissions: new Map(
            Object.entries(resp.projectPermissions).map(([k, v]) => [k as ProjectId, v])
        ),
    };
}

export const authApi: AuthApi = {
    async authenticated() {
        try {
            const { data: user } = await axios.get<AuthUserResponse>("/api/v0/auth/authenticated");
            return formatAuthUserResponse(user);
        } catch (ex) {
            if (ex instanceof AxiosError && ex.response?.status === 401) {
                return null;
            } else {
                throw ex;
            }
        }
    },
    async login(userName, password, rememberMe) {
        try {
            const { data: user } = await axios.post<AuthUserResponse>("/api/v0/auth/login", {
                userName,
                password,
                rememberMe,
            });
            return formatAuthUserResponse(user);
        } catch (ex) {
            if (ex instanceof AxiosError && ex.response?.status === 400) {
                return null;
            } else {
                throw ex;
            }
        }
    },
    async logout() {
        await axios.post("/api/v0/auth/logout");
    },

    async change(old, newPass) {
        await axios.post("/api/v0/auth/change", { old: old, new: newPass });
    },
    async confirm(userId, token, userName, password) {
        const { data } = await axios.post<AuthUserResponse>("/api/v0/auth/confirm", {
            userId,
            token,
            userName,
            password,
        });

        return formatAuthUserResponse(data);
    },

    async forgot(userName) {
        await axios.post("/api/v0/auth/forgot", { userName });
    },
    async reset(userName, token, password) {
        await axios.post("/api/v0/auth/reset", { userName, token, password });
    },
};
