import axios from "axios";
import { sendPatch } from "../utils";
import type { TicketType, TicketTypeId } from "../tickets";

export type TicketTypeConfigurationId = string & { __brand: "TicketTypeConfigurationId" };
export type TicketTypeConfiguration = {
    id: TicketTypeConfigurationId;
    name: string;
    types: TicketType[];
};

export type TicketTypeEdit = Pick<TicketType, "name" | "icon" | "iconColor" | "description">;

export type TicketTypeApi = {
    getTypeConfigurations(): Promise<TicketTypeConfiguration[]>;
    createTypeConfiguration(name: string): Promise<TicketTypeConfiguration>;
    editTypeConfiguration(config: TicketTypeConfigurationId, name: string): Promise<void>;
    deleteTypeConfiguration(config: TicketTypeConfigurationId): Promise<void>;

    createType(
        config: TicketTypeConfigurationId,
        name: string,
        icon: string | null,
        iconColor: string | null,
        description: string | null
    ): Promise<TicketType>;
    editType(
        config: TicketTypeConfigurationId,
        type: TicketTypeId,
        name: string,
        icon: string | null,
        iconColor: string | null,
        description: string | null
    ): Promise<void>;
    patchType(
        config: TicketTypeConfigurationId,
        type: TicketType,
        patch: Partial<TicketTypeEdit>
    ): Promise<void>;
    deleteType(config: TicketTypeConfigurationId, type: TicketTypeId): Promise<void>;
};

export const ticketTypeApi: TicketTypeApi = {
    async getTypeConfigurations() {
        const { data: configs } = await axios.get<TicketTypeConfiguration[]>(
            "/api/v0/project-configurations/types"
        );
        return configs;
    },
    async createTypeConfiguration(name) {
        const { data: config } = await axios.post<TicketTypeConfiguration>(
            `/api/v0/project-configurations/types`,
            {
                name,
            }
        );
        return config;
    },
    async editTypeConfiguration(config, name) {
        await axios.put(`/api/v0/project-configurations/types/${config}`, { name });
    },
    async deleteTypeConfiguration(config) {
        await axios.delete(`/api/v0/project-configurations/types/${config}`);
    },

    async createType(config, name, icon, iconColor, description) {
        const { data: type } = await axios.post<TicketType>(
            `/api/v0/project-configurations/types/${config}/types`,
            { name, icon, iconColor, description }
        );
        return type;
    },
    async editType(config, type, name, icon, iconColor, description) {
        if (icon && !iconColor) {
            throw new Error("iconColor is required when icon is present.");
        }
        await axios.put(`/api/v0/project-configurations/types/${config}/types/${type}`, {
            name,
            icon,
            iconColor,
            description,
        });
    },
    async patchType(config, type, patch) {
        const edited = {
            ...type,
            ...patch,
        };

        const { compare } = await import("fast-json-patch");

        const data = compare(type, edited);
        await sendPatch(`/api/v0/project-configurations/types/${config}/types/${type.id}`, data);
    },
    async deleteType(config, type) {
        await axios.delete(`/api/v0/project-configurations/types/${config}/types/${type}`);
    },
};
