import axios from "axios";
import { sendFile } from "./utils";
import { OrganizationId } from "@/api/organizations";
import { ProjectId } from "@/api/projects";

export type UserSearchRequest = {
    anyNameField: string | null;
    userName: string | null;
    displayName: string | null;
    email: string | null;

    organizationId: OrganizationId | null;
    projectId: ProjectId | null;

    order: UserSearchOrder | null;
    direction: UserSearchDirection | null;
};

export type UserSearchResults = {
    id: UserId;
    userName: string;
    email: string;
    displayName: string | null;
    avatarId: AvatarId | null;
};

export type SearchResult = {
    users: UserSearchResults[];
    total: number;
};

export enum UserSearchOrder {
    DisplayName,
    UserName,
    Email,
}

export enum UserSearchDirection {
    Ascending,
    Descending,
}

export type UserId = string & { __brand: "UserId" };
export type User = {
    id: UserId;
    userName: string;
    email: string;
    displayName: string | null;
    avatarId: AvatarId | null;
};

export type UserEditModel = {
    displayName: string | null;
    userName: string | null;
};

export type AvatarId = string & { __brand: "AvatarId" };
export type Avatar = {
    id: AvatarId;
    uploaderId: UserId | null;
    url: string;
};

export type UsersApi = {
    search(
        request: UserSearchRequest,
        page: number | null,
        perPage: number | null
    ): Promise<SearchResult>;
    createUser(email: string): Promise<User>;
    editSelf(model: UserEditModel): Promise<void>;
    getUsers(): Promise<User[]>;
    getAvatars(): Promise<Avatar[]>;
    uploadUserAvatar(file: File): Promise<Avatar>;
    uploadGenericAvatar(file: File): Promise<Avatar>;
    deleteUserAvatar(): Promise<void>;
};

export const userApi: UsersApi = {
    async search(request, page, perPage) {
        const params = new URLSearchParams();
        if (page != null) params.set("page", page.toString());
        if (perPage != null) params.set("perPage", perPage.toString());

        const { data: result } = await axios.post<SearchResult>(
            `/api/v0/users/search?${params}`,
            request
        );

        return result;
    },
    async createUser(email) {
        const { data: user } = await axios.post<User>("/api/v0/users", { email });
        return user;
    },
    async editSelf(model) {
        await axios.put("/api/v0/users", model);
    },
    async getUsers() {
        const { data: users } = await axios.get<User[]>("/api/v0/users");
        return users;
    },
    async getAvatars() {
        const { data: avatars } = await axios.get<Avatar[]>("/api/v0/users/avatars");
        return avatars;
    },
    async uploadUserAvatar(file) {
        return await sendFile<Avatar>("/api/v0/users/avatar", file);
    },
    async uploadGenericAvatar(file) {
        return await sendFile<Avatar>("/api/v0/users/avatars", file);
    },
    async deleteUserAvatar() {
        await axios.delete(`/api/v0/users/avatar`);
    },
};
