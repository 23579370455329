import axios from "axios";
import { Label, LabelId } from "../tickets";

export type LabelConfigurationId = string & { __brand: "TicketTypeConfigurationId" };
export type LabelConfiguration = {
    id: LabelConfigurationId;
    name: string;
    labels: Label[];
};
export type LabelEditModel = {
    name: string;
};
export type LabelCreateModel = {
    names: LabelEditModel[];
};

export type LabelApi = {
    getLabelConfigurations(): Promise<LabelConfiguration[]>;
    createLabelConfiguration(name: string): Promise<LabelConfiguration>;
    editLabelConfiguration(config: LabelConfigurationId, name: string): Promise<void>;
    deleteLabelConfiguration(config: LabelConfigurationId): Promise<void>;

    createLabels(config: LabelConfigurationId, model: LabelCreateModel): Promise<Label[]>;
    editLabel(config: LabelConfigurationId, label: LabelId, name: string): Promise<void>;
    deleteLabel(config: LabelConfigurationId, label: LabelId): Promise<void>;
};

export const labelApi: LabelApi = {
    async getLabelConfigurations() {
        const { data: configs } = await axios.get<LabelConfiguration[]>(
            "/api/v0/project-configurations/labels"
        );
        return configs;
    },
    async createLabelConfiguration(name) {
        const { data: config } = await axios.post<LabelConfiguration>(
            `/api/v0/project-configurations/labels`,
            {
                name,
            }
        );
        return config;
    },
    async editLabelConfiguration(config, name) {
        await axios.put(`/api/v0/project-configurations/labels/${config}`, { name });
    },
    async deleteLabelConfiguration(config) {
        await axios.delete(`/api/v0/project-configurations/labels/${config}`);
    },

    async createLabels(config, model) {
        const { data: label } = await axios.post<Label[]>(
            `/api/v0/project-configurations/labels/${config}/labels`,
            model
        );
        return label;
    },
    async editLabel(config, label, name) {
        await axios.post(`/api/v0/project-configurations/labels/${config}/labels/${label}`, {
            name,
        });
    },
    async deleteLabel(config, label) {
        await axios.delete(`/api/v0/project-configurations/labels/${config}/labels/${label}`);
    },
};
