<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div ref="body" class="markdown-body" v-html="renderedHtml" />
</template>
<script setup lang="ts">
import {
    computed,
    getCurrentInstance,
    onMounted,
    onUpdated,
    shallowRef,
    toRef,
    triggerRef,
    watch,
} from "vue";
import type MarkdownItType from "markdown-it";
import { useUsersStore } from "@/store/users";
import { useRouter } from "vue-router";
import { useProjectsStore } from "@/store/projects";
import * as markdownPlugins from "@/markdown";
import type { Attachment, AttachmentId } from "@/api/tickets";
import { injectProject } from "@/composables/project";
import { injectAttachments } from "@/composables/attachments";
// Partially based off of https://github.com/miaolz123/vue-markdown

type Props = {
    source: string;
    attachments: readonly Attachment[];
};

const props = withDefaults(defineProps<Props>(), {
    attachments: () => [],
});

const users = useUsersStore();
const projects = useProjectsStore();

const md = shallowRef<MarkdownItType>();

// so we can dynamically load users
users.$subscribe((_, s) => {
    if (md.value) {
        md.value.use(markdownPlugins.userMentions, s.users);
        triggerRef(md);
    }
});

const projectProvide = injectProject();
const { shownAttachment } = injectAttachments();

// and same for projects
projects.$subscribe((_, s) => {
    if (md.value) {
        md.value.use(markdownPlugins.ticketMentions, s.projects, projectProvide?.project.value);
        triggerRef(md);
    }
});

const renderedHtml = computed(() => (md.value ? md.value.render(props.source) : ""));

if (projectProvide) {
    watch(projectProvide.project, (p) => {
        md.value?.use(markdownPlugins.ticketMentions, projects.projects, p);
        triggerRef(md);
    });
}

const attachments = toRef(props, "attachments");

watch(
    () => attachments.value,
    (a) => setMd({ ...props, attachments: a })
);
onMounted(update);
onUpdated(update);

let ready = false;

const { default: MarkdownIt } = await import("markdown-it");
const { default: hljs } = await import("highlight.js");
const { default: emoji } = await import("markdown-it-emoji");
const { default: textualUml } = await import("markdown-it-textual-uml");
const { default: katex } = await import("@aquabx/markdown-it-katex");
const { default: mermaid } = await import("mermaid");

function formatCode(code: string) {
    const formattedCode = code
        .replace(/(?<!=\s*)(&gt;<\/span>)(?![\n\s]|{{)/g, "$1\n")
        .replace(/(?<!&gt)(?<!&lt)(?<!&amp);(?![\n\s])(?=(?:[^'"]|'[^']*'|"[^"]*")*$)/g, ";\n");
    return formattedCode;
}

function addLineClass(pre: HTMLElement) {
    const codeElement = pre.querySelector("code");
    if (!codeElement) return;
    if (codeElement.innerHTML.includes('<span class="line">')) return;
    const innerHTML = codeElement.innerHTML;
    const formatted = formatCode(innerHTML).trimEnd().trimStart();
    const lines = formatted.split("\n");

    const updatedCode = lines.map((line) => `<span class="line">${line || ""}</span>`).join("\n"); // Join with newline characters to preserve line breaks

    codeElement.innerHTML = updatedCode;
}

await import("github-markdown-css/github-markdown.css");

mermaid.initialize({ startOnLoad: false, theme: "dark" });

ready = true;

setMd(props);

function setMd(p: typeof props): void {
    if (!ready) {
        return;
    }

    md.value = new MarkdownIt()
        .use(emoji)
        .use(katex)
        .use(textualUml)
        .use(markdownPlugins.userMentions, users.users)
        .use(markdownPlugins.ticketMentions, projects.projects, projectProvide?.project.value)
        .set({
            highlight: function (str, lang) {
                if (lang && hljs.getLanguage(lang)) {
                    try {
                        return hljs.highlight(str, { language: lang }).value;
                    } catch {
                        // ignore
                    }
                }

                return "";
            },
        });

    if (p.attachments.length > 0) {
        md.value.use(markdownPlugins.attachmentLinks, p.attachments);
        md.value.use(
            markdownPlugins.attachmentImages,
            p.attachments,
            md.value.renderer.rules.image
        );
    }
}

async function update(): Promise<void> {
    // doing it this way, otherwise we'd have to use an inline click or something weird
    const instance = getCurrentInstance();
    if (!instance) {
        return;
    }

    const router = useRouter();

    const body = instance.refs["body"] as HTMLDivElement | null;
    if (!body) {
        return;
    }

    body.querySelectorAll("img.attachment, a.attachment").forEach((m) => {
        const attachmentId = m.getAttribute("data-attachment-id");
        m.addEventListener("click", (ev) => {
            ev.preventDefault();
            shownAttachment.value = attachmentId as AttachmentId | null;
        });
    });
    body.querySelectorAll("a.user-mention").forEach((m) => {
        m.addEventListener("click", (ev) => {
            ev.preventDefault();
            void router.push({
                name: "UserView",
                params: { userId: m.getAttribute("data-user-name") },
            });
        });
    });
    body.querySelectorAll("a.ticket-mention").forEach((m) => {
        m.addEventListener("click", (ev) => {
            ev.preventDefault();
            void router.push({
                name: "TicketView",
                params: { ticketId: m.getAttribute("data-ticket-id") },
            });
        });
    });
    body.querySelectorAll("pre").forEach((e) => {
        addLineClass(e);
    });

    await mermaid.run();
}
</script>
<style lang="scss">
@use "vuetify/lib/styles/settings/variables";

@import "highlight.js/scss/github";

.markdown-body.markdown-body {
    // undo the font change
    font-family: variables.$body-font-family;

    @media (prefers-color-scheme: dark) {
        background-color: rgba(0, 0, 0, 0.25);
    }
}
.markdown-body pre code {
    text-wrap: pretty !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word;
}

pre {
    tab-size: 4;
    counter-reset: lineCounter;
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

pre span.line {
    counter-increment: lineCounter;
    line-height: 1.25rem;
}

pre span.line::before {
    content: counter(lineCounter);
    color: rgba(var(--v-theme-primary-lighten-1), 0.8) !important;
    width: 2rem;
    display: inline-block;
    border-right: 0.0625rem dotted rgba(var(--v-theme-secondary-darken-1), 0.4);
    padding-right: 0.25rem;
    margin-right: 0.5rem;
    text-align: right;
    font-size: 0.85rem;
    line-height: 1.25rem;
}

pre span.line:nth-child(odd)::before {
    background-color: rgba(var(--v-theme-surface-darken-2), 0.7) !important;
}
</style>
