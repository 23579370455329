import { aboutApi } from "@/api/about";
import { defineStore } from "pinia";

export type AboutState = {
    version: string | null;
};

export const useAboutStore = defineStore("about", {
    state: (): AboutState => ({
        version: null,
    }),
    actions: {
        async load() {
            const aboutInfo = await aboutApi.about();
            this.version = aboutInfo.version;
        },
    },
});
