<template>
    <div v-if="!isEmpty()">
        <suspense>
            <VMarkdownInner :source="source" :attachments="attachments" v-bind="$attrs" />
            <template #fallback>
                <!-- TODO: a better skeleton -->
                <pre v-bind="$attrs">{{ source }}</pre>
            </template>
        </suspense>
    </div>
    <div v-else>
        <VMarkdownInner :source="formattedPlaceholder" :attachments="[]" v-bind="$attrs" />
    </div>
</template>
<script setup lang="ts">
import type { Attachment } from "@/api/tickets";
import VMarkdownInner from "./VMarkdownInner.vue";
import { computed } from "vue";

defineOptions({ inheritAttrs: false });

type Props = {
    source: string;
    attachments: readonly Attachment[];
    placeholder?: string;
};

const props = withDefaults(defineProps<Props>(), {
    source: "",
    attachments: () => [],
    placeholder: "No text available.",
});

const formattedPlaceholder = computed(() => "*" + props.placeholder + "*");

function isEmpty(): boolean {
    return props.source == "" && props.attachments.length == 0;
}
</script>
