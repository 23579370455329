import {
    Avatar,
    AvatarId,
    User,
    userApi,
    UserId,
    UserSearchResults,
    UserSearchRequest,
} from "@/api/users";
import { defineStore } from "pinia";

export type UsersState = {
    search: {
        searched: boolean;
        results: UserSearchResults[];
        total: number;
        request: UserSearchRequest;
        options: UserSearchOptions;
    };
    users: Map<UserId, User>;
    usersByName: Map<string, User>;
    avatars: Map<AvatarId, Avatar>;
};

export type UserSearchOptions = {
    page: number | null;
    perPage: number | null;
};

export const useUsersStore = defineStore("users", {
    state: (): UsersState => ({
        search: {
            searched: false,
            results: [],
            total: 0,
            request: {
                anyNameField: null,
                userName: null,
                displayName: null,
                email: null,
                organizationId: null,
                projectId: null,
                order: null,
                direction: null,
            },
            options: {
                page: null,
                perPage: null,
            },
        },
        users: new Map(),
        usersByName: new Map(),
        avatars: new Map(),
    }),
    actions: {
        async load(force = false) {
            await this.loadUsers(force);
            await this.loadAvatars(force);
        },
        async doSearch(
            request: UserSearchRequest,
            page: number | null,
            perPage: number | null,
            storeRequest = true
        ) {
            // Fetch the data from the server
            const result = await userApi.search(request, page, perPage);

            // Update the state with the new data
            this.$state.search.searched = true;
            this.$state.search.results = result.users;
            this.$state.search.total = result.total;

            this.$state.search.options.page = page;
            this.$state.search.options.perPage = perPage;

            if (storeRequest) {
                this.$state.search.request = request;
            }
        },
        async loadUsers(force = false) {
            if (this.$state.users.size > 0 && !force) {
                return;
            }

            const users = await userApi.getUsers();
            this.$state.users = new Map(users.map((u) => [u.id, u]));
            this.$state.usersByName = new Map(users.map((u) => [u.userName, u]));
        },
        async loadAvatars(force = false) {
            if (this.$state.avatars.size > 0 && !force) {
                return;
            }

            const avatars = await userApi.getAvatars();
            this.$state.avatars = new Map(avatars.map((a) => [a.id, a]));
        },
        async createUser(email: string) {
            const user = await userApi.createUser(email);

            if (this.users.size === 0) {
                await this.loadUsers();
            }

            this.users.set(user.id, user);
            this.usersByName.set(user.userName, user);
        },
        async modifyUser(user: User) {
            if (this.users.size === 0) {
                await this.loadUsers();
            }

            this.users.set(user.id, user);
        },
    },
    getters: {
        get: (state) => {
            function isId(v: UserId | string): v is UserId {
                return /^[0-9A-F]{8}-([0-9A-F]{4}-){3}[0-9A-F]{12}?$/i.test(v);
            }

            return (id: UserId | string) =>
                isId(id) ? state.users.get(id) : state.usersByName.get(id);
        },
        getAvatar: (state) => (id: AvatarId) => state.avatars.get(id),
    },
});
