import { useApi } from "@/api";
import { IconSearchResult } from "@/api/icons";
import { defineStore } from "pinia";
import * as pj from "../../package.json";

type IconsState = {
    loaded: boolean;
    searching: boolean;
    searchResult: IconSearchResult[];
};

export const useIconsStore = defineStore("icons", {
    state: (): IconsState => ({
        loaded: false,
        searching: false,
        searchResult: [],
    }),
    actions: {
        async load(force: boolean = false) {
            if (!force && this.loaded) return;
            this.loaded = false;
            await this.search("notification");
            this.loaded = true;
        },
        async search(v: string) {
            if (this.searching) return;
            const version = pj.dependencies["@mdi/font"];
            const { icons } = useApi();
            this.searching = true;
            this.searchResult = [];
            const result = await icons.search(v, version);
            this.searchResult = result;
            this.searching = false;
        },
    },
    getters: {},
});
