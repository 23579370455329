import "@mdi/font/css/materialdesignicons.css";
import "vuetify/lib/styles/main.sass";
import "@/styles/main.scss";
import { createVuetify, type ThemeDefinition } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { ThemeOptions } from "@/api/preferences";

const roushTechLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: "#eeeeee",
        surface: "#ffffff",
        primary: "#6002EE",
        secondary: "#6C6C6C",
        error: "#ff1f1f",
        success: "#09af00",
        info: "#021aee",
        warning: "#ee6002",
    },
};

const roushTechDarkTheme: ThemeDefinition = {
    dark: true,
    colors: {
        background: "#121212",
        surface: "#222222",
        primary: "#976cde",
        secondary: "#949494",
        error: "#ff1f1f",
        success: "#c4f174",
        info: "#bfc7f9",
        warning: "#f78b3b",
    },
};

const diphyllaTheme: ThemeDefinition = {
    dark: true,
    colors: {
        background: "#282A36",
        "on-background": "#F8F8F2",
        surface: "#44475A",
        "on-surface": "#F8F8F2",
        primary: "#BD93F9",
        "on-primary": "#282A36",
        secondary: "#8F9DC9",
        "on-secondary": "#F8F8F2",
        error: "#FF5555",
        "on-error": "#F8F8F2",
        success: "#50FA7B",
        "on-success": "#282A36",
        info: "#FF79C6",
        "on-info": "#F8F8F2",
        warning: "#FFB86C",
        "on-warning": "#F8F8F2",
    },
};

export type ProcyonTheme = {
    name: string;
    themeOption: ThemeOptions;
    value: string | null;
};

export const procyonThemes: ProcyonTheme[] = [
    { name: "Follow System Theme", themeOption: ThemeOptions.Default, value: null },
    {
        name: "RoushTech Light",
        themeOption: ThemeOptions.DefaultLight,
        value: "roushTechLightTheme",
    },
    { name: "RoushTech Dark", themeOption: ThemeOptions.DefaultDark, value: "roushTechDarkTheme" },
    { name: "Diphylla", themeOption: ThemeOptions.Diphylla, value: "diphyllaTheme" },
];

export default createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: "roushTechLightTheme",
        themes: {
            roushTechLightTheme,
            roushTechDarkTheme,
            diphyllaTheme,
        },
        variations: {
            colors: ["primary", "surface", "secondary", "error", "success", "info", "warning"],
            lighten: 2,
            darken: 2,
        },
    },
    defaults: {
        VBtn: {
            color: "primary",
        },
        VCard: {
            VCardActions: {
                VBtn: { variant: "elevated" },
            },
        },
        VCheckbox: {
            color: "primary",
        },
        VCombobox: {
            color: "primary",
        },
        VSwitch: {
            color: "primary",
        },
        VTextField: {
            color: "primary",
        },
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
});
