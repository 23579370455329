import axios from "axios";
import { sendPatch } from "../utils";
import { Priority, PriorityId } from "../tickets";

export type PriorityConfigurationId = string & { __brand: "TicketTypeConfigurationId" };
export type PriorityConfiguration = {
    id: PriorityConfigurationId;
    name: string;
    priorities: Priority[];
};

export type PriorityEdit = Pick<Priority, "name" | "order" | "icon" | "iconColor" | "description">;

export type PriorityApi = {
    getPriorityConfigurations(): Promise<PriorityConfiguration[]>;
    createPriorityConfiguration(name: string): Promise<PriorityConfiguration>;
    editPriorityConfiguration(config: PriorityConfigurationId, name: string): Promise<void>;
    deletePriorityConfiguration(config: PriorityConfigurationId): Promise<void>;

    createPriority(
        config: PriorityConfigurationId,
        name: string,
        order: number,
        icon: string,
        color: string,
        description: string | null
    ): Promise<Priority>;
    editPriority(
        config: PriorityConfigurationId,
        priority: PriorityId,
        edit: PriorityEdit
    ): Promise<void>;
    patchPriority(
        config: PriorityConfigurationId,
        priority: Priority,
        patch: Partial<PriorityEdit>
    ): Promise<void>;
    deletePriority(config: PriorityConfigurationId, priority: PriorityId): Promise<void>;
};

export const priorityApi: PriorityApi = {
    async getPriorityConfigurations() {
        const { data: configs } = await axios.get<PriorityConfiguration[]>(
            "/api/v0/project-configurations/priorities"
        );
        return configs;
    },
    async createPriorityConfiguration(name) {
        const { data: config } = await axios.post<PriorityConfiguration>(
            `/api/v0/project-configurations/priorities`,
            {
                name,
            }
        );
        return config;
    },
    async editPriorityConfiguration(config, name) {
        await axios.put(`/api/v0/project-configurations/priorities/${config}`, { name });
    },
    async deletePriorityConfiguration(config) {
        await axios.delete(`/api/v0/project-configurations/priorities/${config}`);
    },

    async createPriority(config, name, order, icon, iconColor, description) {
        const { data: label } = await axios.post<Priority>(
            `/api/v0/project-configurations/priorities/${config}/priorities`,
            { name, order, icon, iconColor, description }
        );
        return label;
    },
    async editPriority(config, priority, edit) {
        await axios.put(
            `/api/v0/project-configurations/priorities/${config}/priorities/${priority}`,
            edit
        );
    },
    async patchPriority(config, priority, patch) {
        const edited = {
            ...priority,
            ...patch,
        };

        const { compare } = await import("fast-json-patch");

        const data = compare(priority, edited);
        await sendPatch(
            `/api/v0/project-configurations/priorities/${config}/priorities/${priority.id}`,
            data
        );
    },
    async deletePriority(config, priority) {
        await axios.delete(
            `/api/v0/project-configurations/priorities/${config}/priorities/${priority}`
        );
    },
};
