import axios from "axios";
import { OrganizationId } from "./organizations";
import { ProjectId } from "./projects";
import { NotificationMethods, NotificationTypes } from "./subscriptions";
import { UserId } from "./users";

export type NotificationConfigurationId = string & { __brand: "NotificationConfigurationId" };
export type NotificationConfiguration = {
    id: NotificationConfigurationId;
    name: string;
    global: boolean;
    notificationMethod: NotificationMethods;
    configurationData: string;
    notificationType: NotificationTypes;
    minDelayMinutes: number;
    userId?: UserId | null | undefined;
    projectId?: ProjectId | null | undefined;
    organizationId?: OrganizationId | null | undefined;
};

export type NotificationConfigurationRequest = {
    projectIds?: string[];
    organizationIds?: string[];
};

export type NotificationConfigurationCreateModel = Partial<Omit<NotificationConfiguration, "id">>;
export type NotificationConfigurationEditModel = NotificationConfigurationCreateModel & {
    id: NotificationConfigurationId;
};
export type NotificationConfigurationData = {
    global: NotificationConfiguration[];
    user: NotificationConfiguration[];
    project: NotificationConfiguration[];
    organization: NotificationConfiguration[];
};

export type NotificationConfigurationApi = {
    getNotificationConfigurations(
        request: NotificationConfigurationRequest
    ): Promise<NotificationConfigurationData>;
    createNotificationConfiguration(
        model: NotificationConfigurationCreateModel
    ): Promise<NotificationConfiguration>;
    editNotificationConfiguration(model: NotificationConfigurationEditModel): Promise<void>;
    deleteNotificationConfiguration(id: string): Promise<void>;
};

const baseUri: string = "/api/v0/subscriptions/configurations";

export const notificationConfigurationApi: NotificationConfigurationApi = {
    async getNotificationConfigurations(request) {
        const { data: configs } = await axios.post<NotificationConfigurationData>(baseUri, request);
        return configs;
    },

    async createNotificationConfiguration(model) {
        const { data: config } = await axios.put<NotificationConfiguration>(baseUri, model);
        return config;
    },

    async editNotificationConfiguration(model) {
        await axios.patch(baseUri, model);
    },

    async deleteNotificationConfiguration(id) {
        await axios.delete(`${baseUri}/${id}`);
    },
};
