import axios from "axios";

export type UserPreferences = {
    defaultSearchView: TicketSearchView;
    rememberSearchView: boolean;
    preferredTheme: ThemeOptions | null;
};

export type ModifyUserPreferencesModel = Partial<UserPreferences>;

export enum TicketSearchView {
    TableView,
    CompactView,
}

export enum ThemeOptions {
    Default = 0,
    DefaultLight = 1,
    DefaultDark = 2,
    Diphylla = 100,
}

export type PreferencesApi = {
    getPreferences(): Promise<UserPreferences>;
    resetToDefaults(): Promise<UserPreferences>;
    modifyUserPreferences(model: ModifyUserPreferencesModel): Promise<boolean>;
};

export const preferencesApi: PreferencesApi = {
    async getPreferences() {
        const { data: result } = await axios.get<UserPreferences>("/api/v0/preferences");

        return result;
    },

    async resetToDefaults() {
        const { data: result } = await axios.post<UserPreferences>("/api/v0/preferences/defaults");

        return result;
    },

    async modifyUserPreferences(model) {
        const { data: result } = await axios.patch<boolean>("/api/v0/preferences", model);

        return result;
    },
};
