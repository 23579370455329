import { DynamicFormSchemaItemTypes } from "@/components";
import { TicketId } from "./tickets";
import axios from "axios";

export type CustomDataValueItemId = string & { __brand: "CustomDataValueItemId" };
export type CustomDataValueItem = {
    fieldName: string;
    type: DynamicFormSchemaItemTypes;
    schemaItemId: string;
    value: string | null;
};

export type CustomDataValueId = string & { __brand: "CustomDataValueId" };
export type CustomDataValue = {
    id: CustomDataValueId;
    ticketId: TicketId;
    data: CustomDataValueItem[];
};

export type CustomDataSetModel = {
    items: {
        fieldName: string;
        value: string | null;
    }[];
};

export type CustomDataApi = {
    getData(ticket: TicketId, fieldName?: string): Promise<CustomDataValue>;
    setData(ticket: TicketId, model: CustomDataSetModel): Promise<void>;
};

const url: string = "/api/v0/tickets";

function getUrl(ticketId: string) {
    return `${url}/${ticketId}/customData`;
}

export const customDataApi: CustomDataApi = {
    async getData(ticket, fieldName) {
        const { data: result } =
            fieldName != undefined
                ? await axios.get<CustomDataValue>(`${getUrl(ticket)}/${fieldName}`)
                : await axios.get<CustomDataValue>(`${getUrl(ticket)}`);
        return result;
    },

    async setData(ticket, model) {
        await axios.put(`${getUrl(ticket)}`, model);
    },
};
