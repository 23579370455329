import type { User, UserId } from "@/api/users";
import type { default as MarkdownIt } from "markdown-it";
import StateInline from "markdown-it/lib/rules_inline/state_inline";

export function userMentions(md: MarkdownIt, users: Map<UserId, User>) {
    const userMap = new Map(Array.from(users.values()).map((u) => [u.userName.toLowerCase(), u]));

    function createMentions(state: StateInline, silent: boolean) {
        if (state.src[state.pos] !== "@") {
            return false;
        }

        const match = state.src.slice(state.pos + 1).match(/^([a-z0-9@_.-]+)/i);

        if (!match) {
            return false;
        }

        const name = match[0];

        if (!name) {
            return false;
        }

        if (!silent) {
            const user = userMap.get(name.toLowerCase());
            if (!user) {
                return false;
            }

            let token = state.push("link_open", "a", 1);
            token.attrSet("href", `/users/${user.userName}`);
            token.attrJoin("class", "user-mention");
            token.attrSet("data-user-id", user.id);
            token.attrSet("data-user-name", user.userName);

            token = state.push("text", "", 0);
            token.content = user.displayName || user.userName;

            token = state.push("link_close", "a", -1);

            state.pos += name.length + 1;
            return true;
        }

        state.pos += name.length;
        return true;
    }

    const ruleName = "userMentions";

    try {
        md.inline.ruler.at(ruleName, createMentions);
    } catch {
        md.inline.ruler.before("emphasis", ruleName, createMentions);
    }
}
