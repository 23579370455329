import axios from "axios";
import type {
    DynamicFormSchemaItem,
    DynamicFormSchemaItemTypes,
    DynamicFormSelectItem,
} from "@/components";

export type CustomDataConfigurationId = string & { __brand: "CustomDataConfigurationId" };
export type CustomDataConfiguration = {
    id: CustomDataConfigurationId;
    name: string;
    schema: CustomDataField[];
};
export type DbCustomDataConfiguration = {
    id: CustomDataConfigurationId;
    name: string;
    schema: DbCustomDataField[];
};

export enum CustomDataRuleTypes {
    Unknown = "unknown",
    MinimumLength = "minLength",
    MaximumLength = "maxLength",
    MinimumValue = "minvalue",
    MaximumValue = "maxValue",
    Required = "required",
}

export type CustomDataRule = {
    type: CustomDataRuleTypes;
    value: string | number | boolean | null;
};

export type CustomDataFieldId = string & { __brand: "CustomDataFieldId" };
export type CustomFieldDynamicFormSchemaItem = Omit<DynamicFormSchemaItem, "rules"> & {
    rules?: CustomDataRule[];
};
export type DbCustomFieldDynamicFormSchemaItem = Omit<DynamicFormSchemaItem, "rules"> & {
    rules?: string | null;
};
export interface CustomDataField extends CustomFieldDynamicFormSchemaItem {
    id: CustomDataFieldId;
    name: string;
    usedBy?: number;
    rules: CustomDataRule[];
}
export interface DbCustomDataField extends DbCustomFieldDynamicFormSchemaItem {
    id: CustomDataFieldId;
    name: string;
    usedBy?: number;
    rules: string | null;
}

const url = "/api/v0/project-configurations/customData";

export type CustomDataConfigApi = {
    getCustomDataConfigurations(): Promise<CustomDataConfiguration[]>;
    createCustomDataConfiguration(name: string): Promise<CustomDataConfiguration>;
    editCustomDataConfiguration(config: CustomDataConfigurationId, name: string): Promise<void>;
    deleteCustomDataConfiguration(config: CustomDataConfigurationId): Promise<void>;
    createCustomDataField(
        config: CustomDataConfigurationId,
        name: string,
        fieldName: string,
        type: DynamicFormSchemaItemTypes,
        options?: DynamicFormSelectItem[],
        rules?: CustomDataRule[]
    ): Promise<CustomDataField>;
    editCustomDataField(
        config: CustomDataConfigurationId,
        field: CustomDataFieldId,
        name?: string,
        fieldName?: string,
        type?: DynamicFormSchemaItemTypes,
        options?: DynamicFormSelectItem[],
        rules?: CustomDataRule[]
    ): Promise<void>;
    deleteCustomDataField(
        config: CustomDataConfigurationId,
        field: CustomDataFieldId
    ): Promise<void>;
};

export const customDataConfigApi: CustomDataConfigApi = {
    async getCustomDataConfigurations() {
        const { data: configs } = await axios.get<DbCustomDataConfiguration[]>(`${url}`);
        return configs.map<CustomDataConfiguration>((c) => ({
            id: c.id,
            name: c.name,
            schema: c.schema.map((s) => ({
                id: s.id,
                name: s.name,
                fieldName: s.fieldName,
                type: s.type,
                rules: JSON.parse(s.rules ?? "[]") as CustomDataRule[],
                options: s.options ?? [],
            })),
        }));
    },

    async createCustomDataConfiguration(name) {
        const { data: config } = await axios.post<CustomDataConfiguration>(`${url}`, {
            name: name,
        });
        return config;
    },

    async editCustomDataConfiguration(config, name) {
        await axios.put(`${url}/${config}`, { name: name });
    },

    async deleteCustomDataConfiguration(config) {
        await axios.delete(`${url}/${config}`);
    },

    async createCustomDataField(config, name, fieldName, type, options, rules) {
        const { data: configuration } = await axios.post<DbCustomDataField>(
            `${url}/${config}/fields`,
            {
                name,
                fieldName,
                type,
                options,
                rules: JSON.stringify(rules),
            }
        );

        return {
            id: configuration.id,
            name: configuration.name,
            fieldName: configuration.fieldName,
            type: configuration.type,
            options: configuration.options ?? [],
            rules: JSON.parse(configuration.rules ?? ""),
        };
    },

    async editCustomDataField(config, field, name, fieldName, type, options, rules) {
        await axios.put(`${url}/${config}/fields/${field}`, {
            name,
            fieldName,
            type,
            options,
            rules: JSON.stringify(rules),
        });
    },

    async deleteCustomDataField(config, field) {
        await axios.delete(`${url}/${config}/fields/${field}`);
    },
};
