import axios, { AxiosError } from "axios";

export type IconSearchResult = {
    name: string;
    displayName: string;
    aliases: string[];
    styles: string[];
    tags: string[];
};

export type IconApi = {
    search(v: string, version?: string): Promise<IconSearchResult[]>;
    import(): Promise<string>;
};

export const iconApi: IconApi = {
    async search(v, version) {
        try {
            const { data: result } = !version
                ? await axios.get<IconSearchResult[]>(`/api/v0/icons/${v}`)
                : await axios.get<IconSearchResult[]>(`/api/v0/icons/${v}?version=${version}`);
            return result;
        } catch (e) {
            if (e instanceof AxiosError) return [];
            else throw e;
        }
    },
    async import() {
        try {
            const { data: result } = await axios.post<string>("/api/v0/icons/import");
            return result;
        } catch (e) {
            if (e instanceof AxiosError) return "";
            else throw e;
        }
    },
};
