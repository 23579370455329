import axios from "axios";

export type SentryConfig = {
    dsn: string;
    release: string;
    environment: string;
    debug: boolean;
    ip: string;
};

export type SentryApi = {
    get(): Promise<SentryConfig>;
};

export const sentryApi: SentryApi = {
    async get() {
        const response = await axios.get<SentryConfig>("/api/v0/sentry");
        return response.data;
    },
};
