import { Project } from "@/api/projects";
import { InjectionKey, provide, inject, ref, Ref } from "vue";

export type ProjectProvide = {
    project: Ref<Project | undefined>;
};

export const ProjectProvideKey: InjectionKey<ProjectProvide> = Symbol.for("project-provide");

export function provideProject() {
    const project = ref<Project>();

    const projectProvide = {
        project,
    };

    provide(ProjectProvideKey, projectProvide);

    return projectProvide;
}

export function injectProject() {
    return inject(ProjectProvideKey, undefined);
}
