import axios from "axios";
import type { OrganizationId, OrganizationPermission } from "./organizations";
import type { ProjectPermission } from "./projects";
import type { UserId } from "./users";

export type TeamId = string & { __brand: "TeamId" };

export type Team = {
    id: TeamId;
    organizationId: OrganizationId;
    name: string;
    organizationPermissions: OrganizationPermission[];
    permissions: ProjectPermission[];
    users: UserId[];
};

export type TeamApi = {
    getTeams(): Promise<Team[]>;

    delete(team: TeamId): Promise<void>;

    setOrganizationPermissions(team: TeamId, permissions: OrganizationPermission[]): Promise<void>;

    setPermissions(team: TeamId, permissions: ProjectPermission[]): Promise<void>;

    addUsers(team: TeamId, users: UserId[]): Promise<void>;
    removeUser(team: TeamId, user: UserId): Promise<void>;
};

export const teamApi: TeamApi = {
    async getTeams() {
        const { data: teams } = await axios.get<Team[]>(`/api/v0/teams`);

        return teams;
    },

    async delete(team) {
        await axios.delete(`/api/v0/teams/${team}`);
    },

    async setOrganizationPermissions(team, permissions) {
        await axios.put(`/api/v0/teams/${team}/organization-permissions`, { permissions });
    },

    async setPermissions(team, permissions) {
        await axios.put(`/api/v0/teams/${team}/project-permissions`, { permissions });
    },

    async addUsers(team, users) {
        await axios.put<void>(`/api/v0/teams/${team}/users`, users);
    },
    async removeUser(team, user) {
        await axios.delete<void>(`/api/v0/teams/${team}/users/${user}`);
    },
};
