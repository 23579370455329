import {
    Label,
    LabelId,
    Priority,
    PriorityId,
    Resolution,
    ResolutionId,
    Ticket,
    ticketApi,
    TicketLinkType,
    TicketLinkTypeId,
    TicketSearchRequest,
    TicketSearchResult,
    TicketStatus,
    TicketStatusId,
    TicketType,
    TicketTypeId,
} from "@/api/tickets";
import { isDefined } from "@/utils";
import { defineStore } from "pinia";
import Sortable from "sortablejs";

export type TicketsState = {
    search: {
        searched: boolean;
        results: TicketSearchResult[];
        total: number;
        request: TicketSearchRequest;
        options: TicketSearchOptions;
    };
    labels: Map<LabelId, Label>;
    linkTypes: Map<TicketLinkTypeId, TicketLinkType>;
    priorities: Map<PriorityId, Priority>;
    resolutions: Map<ResolutionId, Resolution>;
    statuses: Map<TicketStatusId, TicketStatus>;
    types: Map<TicketTypeId, TicketType>;
};

export type TicketSearchOptions = {
    page: number | null;
    perPage: number | null;
};

export type SortableType = Sortable;

export interface SortableEvent {
    oldIndex: number;
    newIndex: number;
}

export const useTicketsStore = defineStore("tickets", {
    state: (): TicketsState => ({
        search: {
            searched: false,
            results: [],
            total: 0,
            request: {
                text: null,
                project: null,
                assignee: null,
                assigned: null,
                reporter: null,
                priorities: null,
                resolutions: null,
                resolved: null,
                affectVersions: null,
                hasAffectVersion: null,
                fixVersions: null,
                hasFixVersion: null,
                order: null,
                direction: null,
                statuses: null,
                labels: null,
                types: null,
            },
            options: {
                page: null,
                perPage: null,
            },
        },
        labels: new Map(),
        linkTypes: new Map(),
        priorities: new Map(),
        resolutions: new Map(),
        statuses: new Map(),
        types: new Map(),
    }),
    actions: {
        async load(force = false) {
            await this.loadLabels(force);
            await this.loadLinkTypes(force);
            await this.loadPriorities(force);
            await this.loadResolutions(force);
            await this.loadStatuses(force);
            await this.loadTypes(force);
        },
        async loadLabels(force = false) {
            if (this.$state.labels.size > 0 && !force) {
                return;
            }

            const labels = await ticketApi.getLabels();
            this.$state.labels = new Map(labels.map((l) => [l.id, l]));
        },
        async loadLinkTypes(force = false) {
            if (this.$state.linkTypes.size > 0 && !force) {
                return;
            }

            const linkTypes = await ticketApi.getLinkTypes();
            this.$state.linkTypes = new Map(linkTypes.map((lt) => [lt.id, lt]));
        },
        async loadPriorities(force = false) {
            if (this.$state.priorities.size > 0 && !force) {
                return;
            }

            const priorities = await ticketApi.getPriorities();
            this.$state.priorities = new Map(priorities.map((l) => [l.id, l]));
        },
        async loadResolutions(force = false) {
            if (this.$state.resolutions.size > 0 && !force) {
                return;
            }

            const resolutions = await ticketApi.getResolutions();
            this.$state.resolutions = new Map(resolutions.map((t) => [t.id, t]));
        },
        async loadStatuses(force = false) {
            if (this.$state.statuses.size > 0 && !force) {
                return;
            }

            const statuses = await ticketApi.getStatuses();
            this.$state.statuses = new Map(statuses.map((t) => [t.id, t]));
        },
        async loadTypes(force = false) {
            if (this.$state.types.size > 0 && !force) {
                return;
            }

            const types = await ticketApi.getTypes();
            this.$state.types = new Map(types.map((t) => [t.id, t]));
        },

        async doSearch(
            request: TicketSearchRequest,
            page: number | null,
            perPage: number | null,
            storeRequest = true
        ) {
            // Fetch the data from the server
            const result = await ticketApi.search(request, page, perPage);

            // Update the state with the new data
            this.$state.search.searched = true;
            this.$state.search.results = result.tickets;
            this.$state.search.total = result.total;

            this.$state.search.options.page = page;
            this.$state.search.options.perPage = perPage;

            if (storeRequest) {
                this.$state.search.request = request;
            }
        },

        async setLabels(ticket: Ticket, updateLabels: Label[], newLabels: Label[]): Promise<void> {
            const ids = updateLabels.map((ul) => ul.id);
            ids.push(...newLabels.map((nl) => nl.id));
            await ticketApi.setLabels(ticket.id, ids);
            newLabels.forEach((nl) => {
                this.$state.labels.set(nl.id, nl);
            });
        },
    },
    getters: {
        getLabels: (state) => (ticket: Ticket) =>
            ticket.labels.map((l) => state.labels.get(l)).filter(isDefined),
        getLinkType: (state) => (link: TicketLinkTypeId) => state.linkTypes.get(link),
        getPriority: (state) => (ticket: Ticket | TicketSearchResult) =>
            state.priorities.get(ticket.priorityId),
        getResolution: (state) => (ticket: Ticket) =>
            ticket.resolutionId ? state.resolutions.get(ticket.resolutionId) : undefined,
        getStatus: (state) => (ticket: Ticket | TicketSearchResult) =>
            state.statuses.get(ticket.ticketStatusId),
        getType: (state) => (ticket: Ticket | TicketSearchResult) =>
            state.types.get(ticket.ticketTypeId),
    },
});
