import { ref } from "vue";
import { inject, InjectionKey, onBeforeUnmount, provide, Ref } from "vue";
import { RouteLocationRaw } from "vue-router";

export type AppBarTabItem = {
    value: string;
    text: string;
};

export type AppBarItem = {
    key: string;
    title?: string;
    icon?: string;
    tab?: string;
    setTab?: (v: string) => void;
    tabs?: AppBarTabItem[] | undefined;
    to?: RouteLocationRaw | undefined;
    breadcrumbs?: AppBarItemBreadcrumb[] | undefined;
};

export type AppBarItemBreadcrumb = {
    title: string;
    to?: RouteLocationRaw;
};

export type AppBarProvide = {
    items: Ref<Ref<AppBarItem>[]>;
};

export const AppBarKey: InjectionKey<AppBarProvide> = Symbol.for("app-bar");

export function provideAppBar() {
    const items = ref<Ref<AppBarItem>[]>([]);

    const appBar = {
        items,
    };

    provide(AppBarKey, appBar);
}

export function useAppBar(): AppBarProvide {
    const appBar = inject(AppBarKey);

    if (!appBar) {
        throw new Error("Failed to find injected app bar.");
    }

    return appBar;
}

export function useAppBarItem(item: Ref<AppBarItem>) {
    const appBar = useAppBar();

    appBar.items.value.push(item);

    onBeforeUnmount(() => appBar.items.value.pop());
}
