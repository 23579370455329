import { Attachment } from "@/api/tickets";
import { convertToUnit } from "@/utils";
import type { default as MarkdownIt } from "markdown-it";
import type { RenderRule } from "markdown-it/lib/renderer";

export function attachmentImages(
    md: MarkdownIt,
    attachments: Attachment[],
    originalImage: RenderRule
) {
    const attachmentMap = new Map(attachments.map((a) => [a.fileName, a]));

    md.renderer.rules.image = function (tokens, idx, options, env, self): string {
        const token = tokens[idx];
        const attr = token?.attrGet("src");

        if (!token || !attr) {
            return originalImage(tokens, idx, options, env, self);
        }

        const [src, args] = attr.split("?");
        if (!src) {
            throw new Error("This will never happen.");
        }

        const attachment = attachmentMap.get(decodeURIComponent(src).replaceAll("+", " "));
        if (!attachment) {
            return originalImage(tokens, idx, options, env, self);
        }

        const params = new URLSearchParams(args);

        const thumbnail = params.has("thumbnail");

        if (thumbnail) token.attrJoin("class", "attachment-thumbnail");
        else {
            token.attrJoin("class", "attachment");
            token.attrJoin("data-attachment-id", attachment.id);
        }

        token.attrSet(
            "src",
            thumbnail && attachment.thumbnailUrl ? attachment.thumbnailUrl : attachment.url
        );

        const width = convertToUnit(params.get("width"));
        const maxWidth = convertToUnit(params.get("max-width"));
        const height = convertToUnit(params.get("height"));
        const maxHeight = convertToUnit(params.get("max-height"));

        if (width || maxWidth || height || maxHeight) {
            let style = width ? `width: ${width};` : "";
            style += maxWidth ? `max-width: ${maxWidth};` : "";
            style += height ? `height: ${height};` : "";
            style += maxHeight ? `max-height: ${maxHeight};` : "";

            token.attrSet("style", style);
        }

        const img = originalImage(tokens, idx, options, env, self);

        if (thumbnail) {
            return `<a class="attachment" data-attachment-id="${attachment.id}" href="${attachment.url}">${img}</a>`;
        } else {
            return img;
        }
    };
}

export default attachmentImages;
