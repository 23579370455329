import { createApp, PropType } from "vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { sentryApi } from "./api/sentry";
import { useAuthStore } from "./store/auth";
import { useUsersStore } from "./store/users";
import VMarkdown from "@/components/VMarkdown.vue";
import { useSubscriptionsStore } from "./store/subscriptions";
import VueCookies from "vue-cookies";

declare global {
    function idProp<T extends string>(): PropType<T>;
    function objProp<T>(): T extends string ? false : PropType<Readonly<T>>;
    function arrProp<T>(): PropType<readonly T[]>;
}

globalThis.idProp = function idProp<T extends string>(): PropType<T> {
    return String as unknown as PropType<T>;
};

globalThis.objProp = function objProp<T>() {
    return Object as unknown as T extends string ? false : PropType<Readonly<T>>;
};

globalThis.arrProp = function arrProp<T>() {
    return Array as PropType<readonly T[]>;
};

void (async function () {
    const pinia = createPinia();

    const authStore = useAuthStore(pinia);
    const userStore = useUsersStore(pinia);
    const subscriptionsStore = useSubscriptionsStore(pinia);

    const authenticated = await authStore.authenticated();

    if (authenticated) {
        // pre-load users and avatars
        await userStore.load();
        await subscriptionsStore.load();
    }

    // import late so the objProp stuff gets set before we start trying to load components
    const { default: App } = await import(/* webpackChunkName: "init" */ "./App.vue");

    const app = createApp(App)
        .component("v-markdown", VMarkdown)
        .use(router)
        .use(pinia)
        .use(VueCookies, { expires: "90d" })
        .use(vuetify);

    const sentryConfig = await sentryApi.get();
    Sentry.init({
        app,
        dsn: sentryConfig.dsn,
        tunnel: "/tunnel",
        release: sentryConfig.release,
        environment: sentryConfig.environment,
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        enabled: true,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        debug: sentryConfig.debug,
    });

    await router.isReady();

    const isGuestPage = router.currentRoute.value.matched.some((r) => r.meta["guest"]);

    if (!authenticated && !isGuestPage) {
        // store the route and redirect to login
        authStore.route = router.currentRoute.value.path;
        await router.push({ name: "AuthLogin" });
    } else if (authenticated && isGuestPage && router.currentRoute.value.path !== "/") {
        // redirect away from guest page
        await router.push("/");
    }

    router.beforeEach((to, _from, next) => {
        const guest = to.matched.some((m) => m.meta["guest"]);

        if (!guest && !authStore.$state.isAuthenticated) {
            next({ name: "AuthLogin" });
        } else if (guest && authStore.$state.isAuthenticated) {
            next("/");
        } else {
            next();
        }
    });

    app.mount("#app");
})();
