import { authApi } from "@/api/auth";
import { organizationApi } from "./organizations";
import { emailApi } from "./emails";
import { projectConfigApi } from "./projectConfigs";
import { projectApi } from "./projects";
import { teamApi } from "./teams";
import { ticketApi } from "./tickets";
import { userApi } from "./users";
import { followUpApi } from "./followups";
import { subscriptionApi } from "./subscriptions";
import { notificationConfigurationApi } from "./notificationConfigurations";
import { iconApi } from "./icons";
import { customDataApi } from "./customData";

const api = {
    auth: authApi,
    organizations: organizationApi,
    emails: emailApi,
    projects: projectApi,
    projectConfigs: projectConfigApi,
    teams: teamApi,
    tickets: ticketApi,
    users: userApi,
    followUps: followUpApi,
    subscriptions: subscriptionApi,
    notificationConfigurations: notificationConfigurationApi,
    icons: iconApi,
    customData: customDataApi,
};

export type Api = typeof api;

export function useApi(): Api {
    return api;
}
