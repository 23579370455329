import axios from "axios";
import { sendPatch } from "./utils";
import type { OrganizationId } from "./organizations";
import type { ProjectConfigurationId } from "./projectConfigs";
import type { TeamId } from "./teams";
import type { UserId } from "./users";

export enum ProjectPermission {
    /** Administrator access to a project. */
    ProjectAdministrator,
    /** Browse a project and its tickets. */
    CanViewProject,
    /** Create, edit, and delete milestones and versions. */
    CanEditVersions,
    /** Can be assigned to tickets. */
    CanBeAssignedTickets,
    /** Can Reassign tickets to others. */
    CanReassignTickets,
    /** Create new tickets. */
    CanCreateTickets,
    /** Delete tickets. */
    CanDeleteTickets,
    /** Can set ticket statuses to unresolved statuses. */
    CanChangeTicketStatus,
    /** Can set ticket statuses to resolved. */
    CanResolveTickets,
    /** Can change the priority of tickets. */
    CanChangeTicketPriority,
    /** Can change the type of tickets. */
    CanChangeTicketType,
    /** Can edit the descriptions, titles, and labels of tickets. */
    CanEditTicketDescription,
    /** Create and edit ticket labels. */
    CanManageLabels,
    /** Link tickets to other tickets. */
    CanLinkTickets,
    /** Upload attachments to tickets. */
    CanUploadAttachments,
    /** Delete ticket attachments that other users uploaded. */
    CanDeleteAttachments,
    /** Comment on tickets. */
    CanComment,
    /** Delete comments that other users made. */
    CanDeleteComments,
    /** Edit comments that other users made. */
    CanEditComments,
    /** View the edit history of comments */
    CanViewCommentHistory,
    /** Can report on Tickets */
    CanReportOnTickets,
    /** Comment or edit comments on resolved tickets **/
    CanCommentOnClosedTickets,
}

export type ProjectId = string & { __brand: "ProjectId" };
export type ProjectKey = string & { __brand: "ProjectKey" };
export type Project = {
    id: ProjectId;
    key: ProjectKey;
    name: string;
    organizationId: OrganizationId;
    configurationId: ProjectConfigurationId;
};

export type ProjectEdit = Pick<Project, "name">;

export type MilestoneId = string & { __brand: "MilestoneId" };
export type Milestone = {
    id: MilestoneId;
    name: string;
    startDate: Date | null;
    endDate: Date | null;
};

export type MilestoneEdit = Omit<
    Pick<Milestone, "name" | "startDate" | "endDate">,
    "startDate" | "endDate"
> & {
    name: string;
    startDate: string | null;
    endDate: string | null;
};

export type ProjectTeam = {
    teamId: TeamId;
    organizationId: OrganizationId;
    permissions: ProjectPermission[];
};

export type ProjectVersionId = string & { __brand: "ProjectVersionId" };
export type ProjectVersion = {
    id: ProjectVersionId;
    name: string;
    startDate: Date | null;
    endDate: Date | null;
    freezeDate: Date | null;
    releaseDate: Date | null;
    notes: string | null;
};

export type ProjectVersionEdit = Pick<
    ProjectVersionResponse,
    "name" | "startDate" | "endDate" | "freezeDate" | "releaseDate" | "notes"
>;

type MilestoneResponse = Omit<Milestone, "startDate" | "endDate"> & {
    startDate: string | null;
    endDate: string | null;
};

type ProjectVersionResponse = Omit<
    ProjectVersion,
    "startDate" | "endDate" | "freezeDate" | "releaseDate"
> & {
    startDate: string | null;
    endDate: string | null;
    freezeDate: string | null;
    releaseDate: string | null;
    notes: string | null;
};

function formatMilestone(milestone: MilestoneResponse): Milestone {
    return {
        ...milestone,
        startDate: milestone.startDate ? new Date(milestone.startDate) : null,
        endDate: milestone.endDate ? new Date(milestone.endDate) : null,
    };
}

function formatVersion(version: ProjectVersionResponse): ProjectVersion {
    return {
        ...version,
        startDate: version.startDate ? new Date(version.startDate) : null,
        endDate: version.endDate ? new Date(version.endDate) : null,
        freezeDate: version.freezeDate ? new Date(version.freezeDate) : null,
        releaseDate: version.releaseDate ? new Date(version.releaseDate) : null,
    };
}

export type ProjectApi = {
    keyAvailable(key: string): Promise<boolean>;

    getProjects(): Promise<Project[]>;

    changeOrganization(project: ProjectId, organization: OrganizationId): Promise<void>;

    editProject(project: ProjectId, model: ProjectEdit): Promise<void>;

    getUsers(id: ProjectId): Promise<UserId[]>;

    getMilestones(id: ProjectId): Promise<Milestone[]>;
    createMilestone(
        id: ProjectId,
        name: string,
        startDate?: Date,
        endDate?: Date
    ): Promise<Milestone>;
    editMilestone(
        project: ProjectId,
        milestone: Milestone,
        patch: Partial<MilestoneEdit>
    ): Promise<void>;
    deleteMilestone(project: ProjectId, milestone: MilestoneId): Promise<void>;

    getTeams(id: ProjectId): Promise<ProjectTeam[]>;
    addTeam(project: ProjectId, team: TeamId): Promise<void>;
    removeTeam(project: ProjectId, team: TeamId): Promise<void>;
    setTeamPermissions(
        project: ProjectId,
        team: TeamId,
        permissions: ProjectPermission[]
    ): Promise<void>;

    getVersions(id: ProjectId): Promise<ProjectVersion[]>;
    createVersion(
        id: ProjectId,
        name: string,
        startDate?: Date,
        endDate?: Date,
        freezeDate?: Date
    ): Promise<ProjectVersion>;
    editVersion(
        project: ProjectId,
        version: ProjectVersion,
        patch: Partial<ProjectVersionEdit>
    ): Promise<void>;
    deleteVersion(project: ProjectId, version: ProjectVersionId): Promise<void>;
};

export const projectApi: ProjectApi = {
    async keyAvailable(key) {
        const { data } = await axios.get<{ available: boolean }>(
            `/api/v0/projects/key-available?key=${key}`
        );

        return data.available;
    },

    async getProjects() {
        const { data: projects } = await axios.get<Project[]>(`/api/v0/projects`);

        return projects;
    },

    async changeOrganization(project, organization) {
        await axios.post(`/api/v0/projects/${project}/change-organization/${organization}`);
    },

    async editProject(project, model) {
        await axios.put(`/api/v0/projects/${project}`, model);
    },

    async getUsers(id) {
        const { data: users } = await axios.get<UserId[]>(`/api/v0/projects/${id}/users`);

        return users;
    },

    async getMilestones(id) {
        const { data: milestones } = await axios.get<MilestoneResponse[]>(
            `/api/v0/projects/${id}/milestones`
        );

        return milestones.map((m) => formatMilestone(m));
    },
    async createMilestone(id, name, startDate, endDate) {
        const { data: milestone } = await axios.post<MilestoneResponse>(
            `/api/v0/projects/${id}/milestones`,
            {
                name,
                startDate,
                endDate,
            }
        );

        return formatMilestone(milestone);
    },
    async editMilestone(project, milestone, patch) {
        const strMilestone = {
            id: milestone.id,
            name: milestone.name,
            startDate: milestone.startDate?.toISOString(),
            endDate: milestone.endDate?.toISOString(),
        };

        const edited = {
            ...strMilestone,
            ...patch,
        };

        const { compare } = await import("fast-json-patch");

        const data = compare(milestone, edited);

        await sendPatch(`/api/v0/projects/${project}/milestones/${milestone.id}`, data);
    },
    async deleteMilestone(project, milestone) {
        await axios.delete(`/api/v0/projects/${project}/milestones/${milestone}`);
    },

    async getTeams(id) {
        const { data: teams } = await axios.get<ProjectTeam[]>(`/api/v0/projects/${id}/teams`);

        return teams;
    },
    async addTeam(project, team) {
        await axios.put(`/api/v0/projects/${project}/teams/${team}`);
    },
    async removeTeam(project, team) {
        await axios.delete(`/api/v0/projects/${project}/teams/${team}`);
    },
    async setTeamPermissions(project, team, permissions) {
        await axios.put(`/api/v0/projects/${project}/teams/${team}/permissions`, { permissions });
    },

    async getVersions(id: ProjectId) {
        const { data: versions } = await axios.get<ProjectVersionResponse[]>(
            `/api/v0/projects/${id}/versions`
        );

        return versions.map((v) => formatVersion(v));
    },
    async createVersion(id, name, startDate, endDate, freezeDate) {
        const { data: version } = await axios.post<ProjectVersionResponse>(
            `/api/v0/projects/${id}/versions`,
            { name, startDate, endDate, freezeDate }
        );

        return formatVersion(version);
    },
    async editVersion(project, version, patch) {
        const stringedVersion = {
            id: version.id,
            name: version.name,
            startDate: version.startDate?.toISOString() ?? null,
            endDate: version.endDate?.toISOString() ?? null,
            freezeDate: version.freezeDate?.toISOString() ?? null,
            releaseDate: version.releaseDate?.toISOString() ?? null,
            notes: version.notes,
        };

        const edited = {
            ...stringedVersion,
            ...patch,
        };

        const { compare } = await import("fast-json-patch");

        const data = compare(stringedVersion, edited);

        await sendPatch(`/api/v0/projects/${project}/versions/${version.id}`, data);
    },
    async deleteVersion(project, version) {
        await axios.delete(`/api/v0/projects/${project}/versions/${version}`);
    },
};
